<template>
    <div class="wrapper">
      <div class="logo">
          <img src="..\assets\img\logo有色备份@2x.png">
      </div>
      <div class="title">请选择你想要产品</div>
      <div class="forms1">
          <div class="form1"
            v-for="(form,index) in forms1" :key="index"
            @click="selectproduct(form.title)"
          >
            <div class="form1_icon">
              <img class="form1_img" :src="form.img">
            </div>
            <div class="form1_content">
              <div class="form1_title">{{form.title}}</div>
              <div class="form1_text">{{form.text}}</div>
            </div>
          </div>
      </div>
      <div class="register" @click="selectproduct('无选择产品')">暂不使用产品，先预约看看>></div>
    </div>
</template>

<script>
export default {
  name: 'selectproduct',
  data () {
    return {
      forms1: [{
        id: '8',
        img: require('@/assets/img/form1@2x.png'),
        title: '品牌商城系统',
        text: '品牌商城类小程序/APP定制开发，特价支持'
      }, {
        id: '7',
        img: require('@/assets/img/form2@2x.png'),
        title: '连锁门店系统',
        text: '连锁门店小程序/APP定制开发，仅仅1.98万起'
      }, {
        id: '6',
        img: require('@/assets/img/form3@2x.png'),
        title: '行业一码通',
        text: '行业一码通定制，所有对外服务窗口'
      }, {
        id: '5',
        img: require('@/assets/img/form4@2x.png'),
        title: '酒店管理系统',
        text: '系统定制开发，多语言版本，支持连锁酒店'
      }, {
        id: '4',
        img: require('@/assets/img/form5@2x.png'),
        title: '智能终端采集分析系统',
        text: '本软件为一个针对智能终端的各类数据'
      }, {
        id: '3',
        img: require('@/assets/img/form6@2x.png'),
        title: '劳务众包用工管理系统',
        text: '实现以灵活用工模式进行统一管理'
      }, {
        id: '2',
        img: require('@/assets/img/form7@2x.png'),
        title: '行业ERP定制开发',
        text: '支持100人以上远程协同办公定制价格19.8万起'
      }, {
        id: '1',
        img: require('@/assets/img/form8@2x.png'),
        title: '小程序/APP/WEB定制开发',
        text: '按需定制，价格透明'
      }]
    }
  },
  methods: {
    selectproduct (title) {
      this.$emit('selectproduct', title)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.logo {
  text-align: center;
  width: 1.5rem;
  margin: 0.2rem auto 0.16rem auto;
}
img {
  width: 100%
}
.title {
  font-size: 0.13rem;
  font-family: PingFangSC-Regular;
  text-align: center;
  color: #333333;
  margin-bottom: 0.31rem;
}
.forms1 {
 margin: 0.16rem 0.16rem 0.33rem 0.16rem;
  .form1 {
    position: relative;
    display: flex;
    align-items: center;
    height: 0.7rem;
    margin-bottom: 0.08rem;
    border-radius: 0.08rem;
    box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
    .form1_icon {
      margin: 0 0.19rem 0 0.15rem;
      width: 0.33rem;
    }
    .form1_content {
      text-align: start;
      .form1_title {
        margin-bottom: 0.02rem;
        font-family: PingFangSC-Medium;
        font-size: 0.14rem;
      }
      .form1_text {
        font-family: PingFangSC-Regular;
        font-size: 0.12rem;
        color: #777777
      }
    }
  }
}
.register {
  font-family: PingFangSC-Regular;
  color: #777777;
  text-align: center;
  margin-bottom: 0.14rem;
}
</style>
