<template>
  <div class="wrapper">
    <div v-if=!show>
      <div class="mock" @click="back"></div>
      <div class="content">
        <div class="title">免费获取解决方案</div>
        <div class="button">服务全国客户3000+</div>
        <div class="text">全国都支持，赶紧提交预约吧</div>
        <div class="form"  @click="selectproduct()">
          <div class="selset">{{data.productName}}</div>
          <div class="forward">
            <img src="..\assets\img\forward.png">
          </div>
        </div>
        <div class="form">
          <input class="input" placeholder="请输入您的称呼（必填）"
          type="text"
          v-model="data.name"
          maxlength="10"
          >
        </div>
        <div class="form">
          <input class="input" placeholder="请输入您的手机号（必填）"
          type="text"
          v-model="data.phoneNumber" maxlength="11"
          >
        </div>
        <div class="form1">
          <input class="input" placeholder="验证码（必填）"
          type="text"
          v-model="data.code" maxlength="6"
          >
          <button class="get" @click="send" :disabled="disabled">{{text}}</button>
        </div>
        <div class="submit" @click="handleSubmit"
        :style="{'backgroundColor': buttonType ? '#3974FB' : '#A7BFF5'}"
        >点击提交</div>
      </div>
    </div>
    <Selectproduct
          v-if=show
          @selectproduct="selectproduct"
    ></Selectproduct>
  </div>
</template>

<script>
import { reactive, computed } from 'vue'
import { postJson } from '../service/base'
import { useRouter } from 'vue-router'
import selectButton from '../components/getCode'
import Selectproduct from './selectproduct.vue'

export default {
  name: 'nowreserve',
  components: {
    Selectproduct
  },
  methods: {
    back () {
      this.$router.back()
    }
  },
  setup () {
    const { text, disabled, countTime } = selectButton()
    const router = useRouter()
    const data = reactive({ name: '', phoneNumber: '', code: '', productName: '选择你要的产品', show: false })

    // 页面跳转
    const select = (a) => { router.push(a) }

    // 发送验证码
    const send = async () => {
      try {
        await postJson('/home/qtmSp/activity/sendMessageCode', {
          mobile: data.phoneNumber
        })
        if (data.phoneNumber) {
          if ((/^1[3|4|5|8][0-9]\d{4,8}$/.test(data.phoneNumber))) {
            alert('验证码发送成功')
            countTime()
          } else {
            alert('手机号不正确')
          }
        } else {
          alert('手机号不能为空')
        }
      } catch (e) {
        console.log(e)
      }
    }

    // 定义按钮颜色样式
    const buttonType = computed(() => {
      return !!(data.phoneNumber.length === 11 && data.code.length === 6 && disabled.value && data.name && data.productName !== '选择你要的产品')
    })

    // 提交预约
    const handleSubmit = async () => {
      try {
        if (data.productName === '选择你要的产品') {
          alert('请先选择产品')
        } else {
          const result = await postJson('home/qtmSp/activity/saveH5ActivityForm', {
            mobile: data.phoneNumber,
            messageCode: data.code,
            name: data.name,
            shopAppointId: '3ad84623ed044f9fb27e9562aefbc66a',
            ticket: data.productName
          })
          if (data.name && data.phoneNumber && data.code) {
            if (result.data.code === 200) {
              alert('您已预约成功')
              data.name = ''
              data.phoneNumber = ''
              data.code = ''
              data.productName = '选择你要的产品'
              router.back()
            } else if (result.data.code === 1002) {
              alert('您已经预约过了')
              data.name = ''
              data.phoneNumber = ''
              data.code = ''
              data.productName = '选择你要的产品'
            } else {
              alert(result.data.msg)
            }
          } else {
            alert('必填选项不能为空')
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    // 选择产品类型
    const selectproduct = (title) => {
      data.show = !data.show
      data.productName = title
    }

    // 是否隐藏产品明细
    const show = computed(() => { return data.show })

    return {
      data,
      text,
      disabled,
      send,
      handleSubmit,
      select,
      selectproduct,
      show,
      buttonType
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mock {
  height: 1.9rem;
  background-color: #7f7f7f;
}
.content {
  height: 4.25rem;
}
.title {
  text-align: center;
  font-size: 0.28rem;
  color: #3974FB;
  padding: 0.24rem 0 0.12rem 0;

}
.button {
  font-size: 0.16rem;
  line-height: 0.32rem;
  width: 1.66rem;
  text-align: center;
  margin: 0 auto 0.16rem auto;
  background: linear-gradient(to right,#6BACFD,#3974FB);
  color: #ffffff;
}
.text {
  font-size: 0.16rem;
  text-align: center;
  color: #252525;
  margin-bottom: 0.3rem;
}
.form {
  margin: 0 0.16rem 0.12rem 0.16rem;
  box-sizing: border-box;
  height: 0.4rem;
  font-size: 0.14rem;
  border: 0.005rem solid #DADADA;
  display: flex;
  .input {
    width: 3rem;
    height: 0.18rem;
    padding: 0.1rem 0.12rem 0.1rem 0.12rem;
    outline: none;
    border: none;
  }
  .selset {
    width: 3rem;
    height: 0.18rem;
    padding: 0.1rem 0.12rem 0.1rem 0.12rem;
    outline: none;
    border: none;
  }
  .forward {
    padding-top: 0.12rem;
  }
}
.form1 {
  position: relative;
  margin: 0 0.16rem 0.7rem 0.16rem;
  box-sizing: border-box;
  height: 0.4rem;
  font-size: 0.14rem;
  border: 0.005rem solid #DADADA;
  .input {
    width: 2.38rem;
    height: 0.18rem;
    padding: 0.1rem 0.12rem 0.1rem 0.12rem;
    outline: none;
    border: none;
    margin-right: 0.12rem;
  }
  .get {
    position: absolute;
    font-size: 0.13rem;
    font-family: PingFangSC-Regular;
    text-align: center;
    width: 0.8rem;
    height: 0.24rem;
    padding: 0.03rem 0.04rem;
    border: 0.005rem solid rgba(30, 30, 30, 1);
    box-sizing: border-box;
    bottom: 0.08rem;
    right:0.08rem;
    background-color: #ffffff;
  }
}
.submit {
  font-size: 0.14rem;
  font-family: submit;
  color: #ffffff;
  text-align: center;
  width: 3.43rem;
  line-height: 0.4rem;
  margin: 0 auto;
}
::-webkit-input-placeholder {
      color: #CFCFCF
    }
</style>
